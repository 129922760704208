import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

import Header from '../components/Header';
import Intro from '../components/Intro';
import About from '../components/About';
import Services from '../components/Services';
import { Contacts } from '../components/Contacts';

const IndexPage = props => {
  const sections = props.data.sectionsData.edges[0].node.modules;
  const data = props.data.sectionsData.edges[0].node.modules.map(item => item);
  const intro = data[0];
  const about = data[1];
  const services = data[2];

  
  return (
    <Layout>
        
        <SEO
          title={'Neobelisk.co'}
          keywords={[
            `web3`,
            `product`,
            `strategy`,
            'consulting',
            'decentralization',
            'ux'
          ]}
        />
        
        <Header
          data={data}
        />

        <Intro 
          data={intro} 
        />

        <About 
          data={about} 
        />

        <Services 
          data={services} 
        />

        <Contacts />

    </Layout>
  );
};

export default IndexPage;

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  // about: PropTypes.object,
  // folio: PropTypes.object,
  // jobs: PropTypes.object
};

export const query = graphql`
  query Index {
    sectionsData: allContentfulSection {
      edges {
        node {
          modules {
            __typename 
            ... on ContentfulAboutUs {
              id
              slug
              description {
                raw
              }
              pretitle
              title
              image {
                id
                gatsbyImageData(layout: FULL_WIDTH, width: 1800)
                file {
                  url
                }
              }
            }
            ... on ContentfulIntro {
              id
              slug
              description {
                raw
              }
              pretitle
              title
              image {
                id
                file {
                  url
                }
              }
              buttons {
                id
                link
                text
                type
              }
            }
            ... on ContentfulOurServices {
              id
              slug
              description {
                raw
              }
              pretitle
              title
              image {
                id
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;