import React from 'react';
import Section from '../Section';
// import Video from '../Video';
import './header.scss';

const Logo = () => {
  return (
    <svg width="175" height="65" viewBox="0 0 96 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M93.216 0H67.3487L68.6958 2.81739H93.216V33.1826H2.784V2.81739H61.9076L63.2331 0H2.784H0V2.81739V33.1826V36H2.784H93.216H96V33.1826V2.81739V0H93.216Z" fill="white"/>
      <path d="M15.5428 7.6758V28.3367H12.8238L10.1018 18.1972V28.3335H7.38281V7.67267H10.1018L12.8238 17.8435V7.6758H15.5428Z" fill="white"/>
      <path d="M24.2509 10.4306H20.1693V15.2765H24.2509V18.0313H20.1693V25.5788H24.2509V28.3335H17.438V7.67267H24.2509V10.4306Z" fill="white"/>
      <path d="M31.587 7.67584H34.306V28.3367H26.1553V7.67584H31.587V7.67584ZM31.587 25.5788V10.4306H28.8742V25.5788H31.587Z" fill="white"/>
      <path d="M44.3829 7.67585V13.3482C44.4025 14.8553 43.8481 16.3124 42.8345 17.4178C43.8093 18.7098 44.3517 20.2822 44.3829 21.9068V28.3242H36.2322V7.66333H44.3953L44.3829 7.67585ZM41.6639 10.4306H38.9419V16.5913C39.6558 16.4683 40.3093 16.1094 40.7999 15.5708C41.3671 14.9699 41.6804 14.1693 41.6732 13.3388V10.4306H41.6639ZM41.6639 23.2936V21.9131C41.6243 20.8504 41.2524 19.8279 40.6017 18.9924L40.5677 18.9705C40.0525 19.2016 39.4988 19.3323 38.9357 19.3555V25.5788H41.6577L41.6639 23.2936Z" fill="white"/>
      <path d="M53.0912 10.4306H49.0097V15.2765H53.0912V18.0313H49.0097V25.5788H53.0912V28.3335H46.2783V7.67267H53.0912V10.4306Z" fill="white"/>
      <path d="M57.7145 25.5788H61.7961V28.3335H54.9832V7.67267H57.7021L57.7145 25.5788Z" fill="white"/>
      <path d="M66.6239 2.81739V28.3336H63.9019V2.81739L65.2706 0.0657349L66.6239 2.81739Z" fill="white"/>
      <path d="M74.3227 15.2765V10.4306H71.6037V15.2765L76.4904 18.9955C76.6626 19.121 76.8022 19.2867 76.8971 19.4787C76.992 19.6706 77.0395 19.8829 77.0355 20.0974V28.3336H68.8662V18.695L71.5882 20.7329V25.5788H74.3227V20.7329L69.4112 17.0671C69.236 16.9446 69.0942 16.7794 68.9989 16.5868C68.9037 16.3942 68.858 16.1804 68.8662 15.9652V7.67584H77.0293V15.2765H74.3227Z" fill="white"/>
      <path d="M88.6172 7.6758L84.9723 14.7819L88.6172 28.3335H85.8146L83.1235 18.3913L81.6526 21.2869V28.3335H78.9336V7.67267H81.6526V15.3047L82.0892 14.4501L83.9473 10.8438L85.5793 7.6758H88.6172Z" fill="white"/>
    </svg>

  );
};

const Header = ({ data }) => {
  return (
    <>
    {/* <Video /> */}
    <Section className="header">
      <div className='header'>
        <h1 className="header__logo header__logo--blue">
          <a href="/"><Logo /></a>
        </h1>
          <ul className='header__menu'>
            <li>
              <a href={'#about-us'}>About us</a>
            </li>
            <li>
              <a href={'#services'}>Our services</a>
            </li>
            <li>
              <a href={'#contact'}>Contact us</a>
            </li>
          </ul>
        </div>
    </Section>
    </>
  )
}

export default Header;
