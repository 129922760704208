import React, { useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import Section from '../Section';
import './Contacts.scss';

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const Contacts = () => {
  const [status, setStatus] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [telegram, setTelegram] = useState('');
  const [discord, setDiscord] = useState('');
  const [message, setMessage] = useState('');
  const [modal, setModal] = useState(false);

  const handleSubmit = e => {
    const data = { 'form-name': 'contact', name, email, telegram, discord, message };
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(data)
    })
      .then(handleSuccess(true))
      .catch(error => handleSuccess(false));

    e.preventDefault();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'name') {
      return setName(value);
    }
    if (name === 'email') {
      return setEmail(value);
    }
    if (name === 'telegram') {
      return setTelegram(value);
    }
    if (name === 'discord') {
      return setDiscord(value);
    }
    if (name === 'message') {
      return setMessage(value);
    }
  };

  const handleSuccess = status => {
    setStatus(status);
    setModal(true);
    setName('');
    setEmail('');
    setTelegram('');
    setDiscord('');
    setMessage('');
    setTimeout(() => {
      setModal(false);
    }, 5000);
  };


  return (
    <Section sectionId={"contact"} className={"contact"}>
      <h3>Let's discuss</h3>
      <form
        name='contact'
        onSubmit={handleSubmit}
        data-netlify='true'
        data-netlify-honeypot='bot'
        onClick={() => setModal(false)}
      >
        <div className='column-wrapper'>
          <input type='hidden' name='form-name' value='contact' aria-label='form-name' />
          <p hidden>
            <label>
              Don’t fill this out: <input name='bot' aria-label='bot' onChange={handleChange} />
            </label>
          </p>
          <div className='column eight offset-by-two'>
            <p className='form-field'>
              <label><span>Name</span></label>
              <input
                  value={name}
                  onChange={handleChange}
                  aria-label='Name'
                  required
                  name='name'
                  type='text'
                  placeholder='Name'
                  minLength='2'
                />
            </p>
            <p className='form-field'>
              <label><span>Email</span></label>
              <input
                  name='email'
                  type='email'
                  value={email}
                  onChange={handleChange}
                  aria-label='Email'
                  required
                  placeholder='Email'
                />
            </p>
            <p className='form-field'>
              <label><span>Telegram</span></label>
              <input
                  name='telegram'
                  type='text'
                  value={telegram}
                  onChange={handleChange}
                  aria-label='Telegram'
                  placeholder='Telegram'
                />
            </p>
            <p className='form-field'>
              <label><span>Discord</span></label>
              <input
                  name='discord'
                  type='text'
                  value={discord}
                  onChange={handleChange}
                  aria-label='Discord'
                  placeholder='Discord'
                />
            </p>
            <p className='form-field'>
              <label><span>Message</span></label>
              <textarea
                  name='message'
                  placeholder='Message'
                  aria-label='Message'
                  value={message}
                  onChange={handleChange}
                  required
                  rows='5'
                  cols='5'
                ></textarea>
            </p>
            <p className='form-field'>
              <button className='submitform' type='submit'>
                Send
              </button>
            </p>
          </div>
          <dialog
            className="contact-dialog"
            data-status={status}
            open={modal}
            >
            <p>
              {status
                ? 'Thank you for reaching out. We\'ll get back to you as soon as possible.'
                : 'Ops. Something went wrong. Please try again'}
            </p>
          </dialog>
        </div>
      </form>
      
    </Section>
  );
};

export default Contacts;
export { Contacts };