import React from 'react';
import RichText from '../../utils/RichText';
import Section from '../Section';
import './services.scss';



const Services = ({ data }) => {

  return (
    <Section className='services' sectionId={data.slug}>
      <div className='column-wrapper'>
          <div className='column five services-intro'>
            <small>{data.pretitle}</small>
            <h2>{data.title}</h2>
          </div>
          <div className='column seven services-list'>
            <RichText data={data.description} />
          </div>
      </div>
    </Section>

)};

export default Services;
export { Services };