import React from 'react';
import Section from '../Section';
import Button from '../Button';
import RichText from '../../utils/RichText';
import './intro.scss';

const Intro = ({ data }) => {
  console.log(data);
  return (
    <Section className='intro' sectionId={data.slug}>
     <div className='column-wrapper'>
      <div className='column seven'>
        <h1>{data.title}</h1>
      </div>
      <div className='column five'>
        <RichText data={data.description} />
        <div className='button-group'>
          <a href="#contact" className="button primary-button">Chat with us</a>
          <a href="#about-us" className="button secondary-button">Learn more</a>
        </div>
      </div>
      </div>
      <div className='intro-img-wrapper'>
        <img src={data.image.file.url} />
      </div>
    </Section>
  )
}

export default Intro;
