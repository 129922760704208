import React from 'react';
import RichText from '../../utils/RichText';
import { Fade } from 'react-awesome-reveal';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Section from '../Section';
import './about.scss';



const About = ({ data }) => {

const image = getImage(data.image);

  return (

    <Section className='about' sectionId={data.slug}>
      <GatsbyImage image={image} className="about-img" fadeIn={false} />
      <div className='column-wrapper'>
          <div className='column eight offset-by-two'>
            <small>{data.pretitle}</small>
            <h2>{data.title}</h2>
            <RichText data={data.description} />
          </div>
      </div>
    </Section>


)};

export default About;
export { About };