import React from 'react';
import './Section.scss';

const Section = ({ children, className, sectionId, ...props }) => (
  <section
    id={`${sectionId}`}
    className={`section section--${className}`}
    {...props}
  >
    <div className='section__inner'>
      {children}
    </div>
    
  </section>
);

export default Section;
export { Section };
